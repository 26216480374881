import { autoinject, bindable, bindingMode, transient, Disposable } from 'aurelia-framework';
import { BindingEngine } from 'aurelia-binding';

@transient()
@autoinject()
export class Files {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) files: File[];
    @bindable({ defaultBindingMode: bindingMode.twoWay }) totMb: number = 0;

    @bindable maxMb: number = 10;

    fileList: FileList;
    infoFiles = "Karta och rutter bifogas automatiskt till anmälan";

    private fileObserver: Disposable;
	
    constructor(private bindingEngine: BindingEngine) {

    }

    bind(): void {
        // Hook up listener on fileList and push to files
        this.fileObserver = this.bindingEngine
        .propertyObserver(this, "fileList")
            .subscribe(() => {
                this.updateCollection();
        });
    }

    unbind(): void {
        this.fileObserver.dispose();
    }

    round(n: number): number {
        n = Math.round(n);
        return n;
    }

    /**
     * Delete a file from the array
     * @param  $index the index to splice from the array
     */
    delete($index: number): void {
        this.totMb = this.totMb - this.files[$index].size;
        this.files.splice($index, 1);
    }

    private updateCollection(): void {
        if (!this.files) {
            this.files = [];  
            this.totMb = 0;
        }

        for (let i = 0; i < this.fileList.length; i++) {
            if (this.files.some(x => x.name === this.fileList.item(i).name)) {
                continue;
            }
            
            this.totMb = this.totMb + this.fileList.item(i).size;
            this.files.push(this.fileList.item(i));
        }
    }
}
