import { bindable, bindingMode } from 'aurelia-framework';

export class FileChooser {

	@bindable disabled: boolean;
	@bindable accept = '';
	@bindable btnText = 'Choose Files';
	@bindable multiple = false;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) files: File[];
	@bindable({ defaultBindingMode: bindingMode.twoWay }) totMb: number;

	input: HTMLInputElement;

	constructor() {

	}

}


