import { PLATFORM, autoinject, bindable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { DeviationReportModal } from './deviation-report-modal/deviation-report-modal';
PLATFORM.moduleName('./deviation-report-modal/deviation-report-modal');

@autoinject()
export class DeviationReport {
	@bindable toolPanelExpanded: boolean;
	static inject = [DialogService];
	constructor(
		private dialogService: DialogService) {
	}
	
	protected openModal() {
		this.dialogService.open({viewModel: DeviationReportModal, overlayDismiss: false});
	}
}