import { autoinject } from 'aurelia-framework';
import { StateService } from 'services/state-service';
import { IMapState, INote, ReportService } from 'components/map/deviation-report/services/report-service';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { base64ArrayBuffer } from 'components/map/deviation-report/services/base64-service';
import { NotePopup } from 'components/common/popups/note-popup';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';
import { IDeviationRoute } from '../models/deviation-route.interface';
import { IRecipient } from '../models/recipient.interface';
import { IDetails } from '../models/details.interface';
import { IDeviationReport } from '../models/deviation-report.interface';

@autoinject()
export class DeviationReportService {

    private readonly lastSenderDetailsKey: string = 'lastSenderDetails';

    constructor(
        private stateService: StateService, 
        private reportService: ReportService, 
        private applicationRepo: ApplicationRepository,
        private notePopup: NotePopup,
		private config: EnvironmentConfiguration) {

    }

    get recipients(): IRecipient[] {
        const emails: string[] = this.config.env.RecipientEmails;

        const recipients = emails.map((x) => {
            const parts = x.split('=');
            return {value: parts[0],email: parts[1]}
        });

        return recipients;
    }

    get notes(): INote[] {
		return this.notePopup.getNotePopups();
    }
    
    get lastSenderDetails(): IDetails {
        const json = localStorage.getItem(this.lastSenderDetailsKey);
		if (json) {
			return JSON.parse(json) as IDetails;
        }
        
        return null;
    }

    get routes(): IDeviationRoute[] {
		const routesJson = JSON.stringify(this.applicationRepo.routesInformation as IDeviationRoute[]);
		const routes = JSON.parse(routesJson) as IDeviationRoute[];
		const index = routes.findIndex(x => x.StartLocation == null);
		if (index != -1) {
			routes.splice(index, 1); 
        }
        
        return routes;
    }

    async saveMapState(routes: IDeviationRoute[], notes: INote[]): Promise<string> {
		if ((!routes || routes.length === 0) && (!notes || notes.length === 0)) {
			return null;
		}

		const toSave: IMapState = {};
		
		const state: string = history.state;
		const stateParts = state.split("@");

		if (stateParts.length > 1) {
			const parts = stateParts[1].split(",");
			toSave.x = +parts[0];
			toSave.y = +parts[1];
			toSave.zoom = +parts[2];
		}

		toSave.routesInformation =  routes;
		toSave.notes = notes;

		return await this.stateService.saveState(toSave);
	}
    
    async sendReport(deviationReport: IDeviationReport): Promise<boolean> {
        return this.reportService.sendReport(deviationReport);
    }

    async toBase64(file: File): Promise<string> {
		const promise = new Promise<string>((resolve, reject) => {
			const reader = new FileReader();
			reader.onload = () => {
				const binaryString = reader.result;
				const base64 = base64ArrayBuffer(binaryString);

				resolve(base64);
			};
			reader.readAsArrayBuffer(file);
		});

		return promise;
	}
}