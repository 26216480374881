import { autoinject, computedFrom, Disposable } from "aurelia-framework";
import { EventAggregator } from 'aurelia-event-aggregator';
import { FeatureEventType } from "vv-constants/feature-event-type.enum";
import { RouteEventItemType } from "vv-constants/route-event-item-type.enum";

@autoinject()
export class ToolPanel{

    toolPanelExpanded = true;
    avvikelseExpanded = true;
	layerPickerExpanded = true;
	newRouteExpanded = true;
    anyFeatureSelected: boolean;
    navPanel: any;
    toolPanel: any;
    showAttribute = false;
    loading = false;
	anyAttributeFeatures: boolean;
	attributeExpanded = true;

    private featureLoadedSubscription: Disposable;
    private featureIdentifiedStartedSubscription: Disposable;

    constructor(private eventAggregator: EventAggregator) {

    }

    attached(): void {
        this.navPanel = document.getElementById("navPanel");
        this.toolPanel = document.getElementById("toolPanel");

        this.featureLoadedSubscription = this.eventAggregator.subscribe(FeatureEventType.FEATURE_LOADED, () => {
            this.loading = false;

            if (this.checkScrollHeight()) {
                this.showAttribute = true;
            }
        });

        this.featureIdentifiedStartedSubscription = this.eventAggregator.subscribe(FeatureEventType.FEATURE_IDENTIFIED_STARTED, (data) => {
			this.loading = true;
        });
    }

    detached(): void {
        this.featureLoadedSubscription.dispose();
        this.featureIdentifiedStartedSubscription.dispose();
    }

    toggle(): void {
        this.toolPanelExpanded = !this.toolPanelExpanded;
        if (!this.toolPanelExpanded) {
			this.attributeExpanded = false;
            this.avvikelseExpanded = false;
            this.layerPickerExpanded = false;
			this.newRouteExpanded = false;
			this.eventAggregator.publish(RouteEventItemType.MINIMIZE_ROUTE, null);
        } 
        else {
			this.attributeExpanded = true;
            this.avvikelseExpanded = true;
            this.layerPickerExpanded = true;
            this.newRouteExpanded = true;
			this.eventAggregator.publish(RouteEventItemType.MAXIMIZE_ROUTES);
		}
	}
	
	protected test = ($event): void => {
		
		if (!this.toolPanelExpanded) {
			$event.stopPropagation();
			this.attributeExpanded = false;
		}
    }

    public get anyExpanded(): boolean {
        if (this.newRouteExpanded || this.layerPickerExpanded ){
            return true;
        }
        return false;
    }

    @computedFrom('navPanel.scrollHeight')
    public get scrollPanel(): string {
        if (!this.navPanel) {
            return;
        }
        
        //clientHieght och offset top är samma som toolPanel, när det blir scroll på nav-panel så kommer det värdet vara högre och man sätter så att det blir scroll
        if (this.navPanel.clientHeight + this.navPanel.offsetTop > this.toolPanel.clientHeight) {
			this.navPanel.style = "overflow-y: scroll";
            
            if (this.navPanel.clientHeight != this.navPanel.scrollHeight) {
                if (this.toolPanelExpanded) {
                    return "width: 458px;";
                }
                else {
                    return "width: 65px";
                }
            }
        }
        else {
            this.navPanel.style = "overflow: visible";
        }
    }

    checkScrollHeight(): boolean {
        if (this.navPanel.scrollTop == 0) {
            return false;
        } 
        else {
            return true;
        }
    }

    scrollToTop(): void {
        const el = document.getElementById("attributePanel");
        if (el) {
            el.scrollIntoView({ 
                behavior: 'smooth' 
              });
        }
        this.showAttribute = false;
    }

    @computedFrom('navPanel.scrollTop')
    public get checkScrollTop(): void {
        if (this.navPanel.scrollTop == 0) {
            this.showAttribute = false;
            return;
        }
    }
}
