// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files ul {
    list-style-type: none;
}

.files li {
    background-color: #bbbbbb;
    margin-top: 5px;
    margin-right: 1px;
    margin-bottom: 5px;
    margin-left: 0px;
    padding: 5px;
}

.files div.file {
    background-color: #bbbbbb;
    padding: 6px;
    margin-top: 1px;
}`, "",{"version":3,"sources":["webpack://./src/components/map/files/files.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,yBAAyB;IACzB,YAAY;IACZ,eAAe;AACnB","sourcesContent":[".files ul {\r\n    list-style-type: none;\r\n}\r\n\r\n.files li {\r\n    background-color: #bbbbbb;\r\n    margin-top: 5px;\r\n    margin-right: 1px;\r\n    margin-bottom: 5px;\r\n    margin-left: 0px;\r\n    padding: 5px;\r\n}\r\n\r\n.files div.file {\r\n    background-color: #bbbbbb;\r\n    padding: 6px;\r\n    margin-top: 1px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
