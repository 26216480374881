import { autoinject, bindable, bindingMode, BindingEngine, Disposable } from 'aurelia-framework';

@autoinject()
export class LayerPickerPanel {
    @bindable toolPanelExpanded: boolean;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) layerPickerExpanded: boolean; // The default selected value
    
    expanded: boolean = true;

    private subscription: Disposable;

    constructor(private bindingEngine: BindingEngine) {
		
	}

    attached(): void {
		this.subscription = this.bindingEngine
		.propertyObserver(this, 'layerPickerExpanded')
		.subscribe((newValue, oldValue) => { 
			if (!this.toolPanelExpanded && newValue === true) {
				this.expand();
			}
		});
	}

	detached() {
		this.subscription.dispose();
	}

	public expand() {
		this.expanded = true;
	}

	protected toggle = (): void => {
		this.expanded = !this.expanded;

		if (!this.toolPanelExpanded && !this.expanded) {
			this.layerPickerExpanded = false;
		}
    }
    
}