// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attribute-panel .toggle-panel-nav {
    position: relative !important;
    text-align: left;
    background-color: #444444 !important;
    color: #fff !important;  
    border: none;
    overflow: visible !important;
    overflow-y: auto;
    float: right;
    z-index: 999;
    box-shadow: none !important;
    width: 100%;
    font-weight: bold;
    border-radius: 0px !important;
}

.attribute-panel .toggle-panel-nav:hover {
    background-color: #000 !important;
}

.attribute-panel .toggle-panel-content.expanded {
    position: relative;
    width: 100%;
    background-color: #eee;
    font-size: 12px;
    line-height: 14px;    
    text-align: left;
    padding: 0px 15px 15px 15px;
    height: auto;
    cursor: initial;
    max-height: 90%;
    overflow-y: scroll;
    overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/map/tool-panel/attribute-panel/attribute-panel.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,oCAAoC;IACpC,sBAAsB;IACtB,YAAY;IACZ,4BAA4B;IAC5B,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,iCAAiC;AACrC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,2BAA2B;IAC3B,YAAY;IACZ,eAAe;IACf,eAAe;IACf,kBAAkB;IAClB,cAAc;AAClB","sourcesContent":[".attribute-panel .toggle-panel-nav {\r\n    position: relative !important;\r\n    text-align: left;\r\n    background-color: #444444 !important;\r\n    color: #fff !important;  \r\n    border: none;\r\n    overflow: visible !important;\r\n    overflow-y: auto;\r\n    float: right;\r\n    z-index: 999;\r\n    box-shadow: none !important;\r\n    width: 100%;\r\n    font-weight: bold;\r\n    border-radius: 0px !important;\r\n}\r\n\r\n.attribute-panel .toggle-panel-nav:hover {\r\n    background-color: #000 !important;\r\n}\r\n\r\n.attribute-panel .toggle-panel-content.expanded {\r\n    position: relative;\r\n    width: 100%;\r\n    background-color: #eee;\r\n    font-size: 12px;\r\n    line-height: 14px;    \r\n    text-align: left;\r\n    padding: 0px 15px 15px 15px;\r\n    height: auto;\r\n    cursor: initial;\r\n    max-height: 90%;\r\n    overflow-y: scroll;\r\n    overflow: auto;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
