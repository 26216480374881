import { autoinject, bindable, bindingMode, Disposable } from 'aurelia-framework';
import { DialogService } from 'aurelia-dialog';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Alert, AlertModel } from 'components/common/dialog/alert';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { RouteEventItemType } from 'vv-constants/route-event-item-type.enum';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';
import { IResistanceSetting } from 'services/routing/models/resistance-settings.interface';
import { ICoordinateSystem } from 'services/routing/models/coordinate-system.interface';
import { IVehicleDefinition } from 'services/routing/models/vehicle-definition.interface';

@autoinject()
export class Routing {

	@bindable toolPanelExpanded: boolean;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) newRouteExpanded: boolean;

	protected routes: IRouteInformation[] = [];
	protected resistanceSettings: IResistanceSetting[];
	protected coordinateSystems: ICoordinateSystem[];
	protected vehicleDefinitions: IVehicleDefinition[];
	protected expanded = false;

	private id = 0;

    private routeCreatedSubscription: Disposable;
    private createRouteCopySubscription: Disposable;
	private createMapStateRoutesSubscription: Disposable;
    private minimizeRoutesSubscription: Disposable;
    private minimizeRouteSubscription: Disposable;
	private switchArrowSubscription: Disposable;
	/**
	 * replaces 'canAddLocations' in appRepo with this implementation
	 */
	canAddLocations = (): boolean => {
		const anySelected = this.routes.find(x => x.Selected);
        if (anySelected) {
            return true;
        }

        return false;
	}

	constructor(
        private eventAggregator: EventAggregator, 
        private dialogService: DialogService, 
        private applicationRepository: ApplicationRepository) {

	}
    
    attached(): void {
        this.routeCreatedSubscription = this.eventAggregator.subscribe(RouteEventItemType.ROUTE_CREATED, (route) => {
			this.addCreatedRouteCopy(route);
		});

		this.createRouteCopySubscription = this.eventAggregator.subscribe(RouteEventItemType.CREATE_ROUTE_COPY, (route) => {
			this.addCreatedCalculatedRouteCopy(route);
		});

		this.createMapStateRoutesSubscription = this.eventAggregator.subscribe(RouteEventItemType.CREATE_MAPSTATE_ROUTES, (routes) => {
			this.routes = routes;
			this.applicationRepository.routesInformation = this.routes;
		});

		this.minimizeRoutesSubscription = this.eventAggregator.subscribe(RouteEventItemType.MINIMIZE_ROUTES, (payload) => {
			if (payload != null && this.routes[0].Id != payload.Id) {
				this.newRouteExpanded = false;
			}
		});

		this.minimizeRouteSubscription = this.eventAggregator.subscribe(RouteEventItemType.MINIMIZE_ROUTE, (payload) => {
			this.expanded = false;
			this.newRouteExpanded = false;
		});

		this.switchArrowSubscription = this.eventAggregator.subscribe("switcharrow", payload => {
			this.expanded = payload.expand;
		})

		this.applicationRepository.canAddLocations = this.canAddLocations;
	}
	
    detached(): void {
        this.routeCreatedSubscription.dispose();
        this.createRouteCopySubscription.dispose();
        this.createMapStateRoutesSubscription.dispose();
        this.minimizeRoutesSubscription.dispose();
        this.minimizeRouteSubscription.dispose();
    }

	/**
	 * Initial route
	 */
	protected newRoute() {
		if (!this.toolPanelExpanded) {
			this.newRouteExpanded = !this.newRouteExpanded;
			this.expanded = !this.expanded;
		}
		else {
			this.expanded = !this.expanded;
		}
		
		this.routes = this.applicationRepository.routesInformation;
		
		const route: IRouteInformation = {
			Id: this.id,
			Selected: true,
			ViaLocations: []
		};

		const checkRoute = this.checkRoute(route);
		if (checkRoute) {
			this.updateValues(this.routes[0]);
			this.eventAggregator.publish(RouteEventItemType.ROUTE_SELECTED, {Id: this.routes[0].Id});
		}
	}

	protected deleteRoutes(event): void {
		event.stopPropagation();

		const alertModel: AlertModel = {
			Mode: "confirm",
			ErrorMessage: "Rensa rutter",
			AbortText: "Avbryt",
			ConfirmText: "Rensa alla rutter",
			ConfirmButtonColor: "red"
		};

		const ok = this.dialogService.open({viewModel: Alert, model: alertModel}).whenClosed(response => {
			if (!response.wasCancelled) {
				this.routes = [];
				this.applicationRepository.routesInformation = this.routes;
			}
		});
		
	}

	protected get anyRoutes(): boolean{
		if (this.applicationRepository.routesInformation.length > 1) {
			return true;
		}
		return false;
	}

	private checkRoute(route: IRouteInformation): boolean{
		if (!this.routes[0]) {
			this.routes.push(route);
		}
		else if (this.routes[0].StartLocation == null) {
			this.clearRoute();
			return false;
		}
		else {
			if (this.routes[0].CalculatedRoute || this.routes.length == 0) {
				this.routes.unshift(route);
			}
			else {
				this.routes.splice(0, 1);
				this.applicationRepository.routesInformation = this.routes;
				return false;
			}
		}
		return true;
	}

	/**
	 * New route
	 * @param data Route data to copy values from
	 */
	private addCreatedRouteCopy(data: any){
		this.routes = this.applicationRepository.routesInformation;
		const route: IRouteInformation = {
			Id: this.id,
			Selected: true,
			ViaLocations: data.ViaLocations,
			StartLocation: data.StartLocation,
			EndLocation: data.EndLocation,
			ResistanceSetting: data.ResistanceSetting,
			Router: data.Router,
			VehicleDefinition: data.VehicleDefinition
		};

		this.routes.unshift(route);
		this.updateValues(this.routes[0]);

		this.eventAggregator.publish(RouteEventItemType.ROUTE_SELECTED, {Id: this.routes[0].Id})
	}

	/**
	 * Clone existing route
	 */
	private addCreatedCalculatedRouteCopy(route: IRouteInformation){
		const originalRouteId = route.Id.toString();
		route.Id = this.id;
		route.Selected = false;
		route.Active = false;
		this.updateValues(route);
		this.eventAggregator.publish(RouteEventItemType.ROUTE_SELECTED, {Id: originalRouteId, IsCopy: true})
	}

	/**
	 *  Store the route in app repo and increment the "route id" by one
	 * @param route The route to store
	 */
	private updateValues(route: IRouteInformation): void {
		this.applicationRepository.routeInformation = route;
		this.id++;
	}

	private clearRoute(): void {
		this.eventAggregator.publish(RouteEventItemType.ROUTE_SELECTED, {Id: this.routes[0].Id, clearRoute: false, expanded: this.expanded});
	}

}
