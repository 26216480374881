// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.deviation-nav,
.deviation-nav:hover,
.deviation-nav:focus {
    text-align: left;
    background-color: #444444 !important;
    color: #fff;  
    overflow: visible !important;
    overflow-y: auto;
    z-index: 99;
    box-shadow: none !important;
    width: 100%;
    font-weight: bold;
    border-radius: 0px;
}
.deviation-nav:hover {
    background-color: #000 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/map/deviation-report/deviation-report.css"],"names":[],"mappings":"AAAA;;;IAGI,gBAAgB;IAChB,oCAAoC;IACpC,WAAW;IACX,4BAA4B;IAC5B,gBAAgB;IAChB,WAAW;IACX,2BAA2B;IAC3B,WAAW;IACX,iBAAiB;IACjB,kBAAkB;AACtB;AACA;IACI,iCAAiC;AACrC","sourcesContent":[".deviation-nav,\r\n.deviation-nav:hover,\r\n.deviation-nav:focus {\r\n    text-align: left;\r\n    background-color: #444444 !important;\r\n    color: #fff;  \r\n    overflow: visible !important;\r\n    overflow-y: auto;\r\n    z-index: 99;\r\n    box-shadow: none !important;\r\n    width: 100%;\r\n    font-weight: bold;\r\n    border-radius: 0px;\r\n}\r\n.deviation-nav:hover {\r\n    background-color: #000 !important;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
