import { autoinject, bindable, bindingMode, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { FeatureEventType } from 'vv-constants/feature-event-type.enum';


@autoinject()
export class AttributePanel {	
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public wideLayout = false;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) anyFeatures: boolean;
	@bindable({ defaultBindingMode: bindingMode.twoWay }) attributeExpanded: boolean;
	@bindable toolPanelExpanded: boolean;

    expanded: boolean;
    featureInfo: any; // The ViewModel
    loading: boolean;

    private featureIdentifiedStartedSubscription: Disposable;
    private featureIdentifiedSubscription: Disposable;
    private noFeatureIdentifiedSubscription: Disposable;

    constructor(private eventAggregator: EventAggregator) {

    }

    attached(): void {
        this.featureIdentifiedStartedSubscription = this.eventAggregator.subscribe(FeatureEventType.FEATURE_IDENTIFIED_STARTED, () => {
            this.loading = true;

		});

        this.featureIdentifiedSubscription = this.eventAggregator.subscribe(FeatureEventType.FEATURE_IDENTIFIED, (data) => {
            this.loading = false;
            this.expanded = true;
            this.eventAggregator.publish(FeatureEventType.FEATURE_LOADED);
        });
        
        this.noFeatureIdentifiedSubscription = this.eventAggregator.subscribe(FeatureEventType.NO_FEATURE_IDENTIFIED, () => {
            this.loading = false;
            this.close();
        });
    }

    detached(): void {
        this.featureIdentifiedStartedSubscription.dispose();
        this.featureIdentifiedSubscription.dispose();
        this.noFeatureIdentifiedSubscription.dispose();
    }

    close(): void {
		this.featureInfo.reset();
	}
	
	protected toggle(): void {
		this.expanded = !this.expanded;
		if (!this.toolPanelExpanded && !this.expanded) {
			this.attributeExpanded = false;
			this.expanded = true;
		}
    }
    
    toggleLayout(): void {
		this.wideLayout = !this.wideLayout;
	}
}