import { autoinject, bindable } from 'aurelia-framework';
import { DialogController, DialogService } from "aurelia-dialog";
import { bindingMode, computedFrom } from 'aurelia-binding';
import { Alert, AlertModel } from 'components/common/dialog/alert';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { IDeviationReport } from '../models/deviation-report.interface';
import { IDeviationRoute } from '../models/deviation-route.interface';
import { INote } from '../services/report-service';
import { IRecipient } from '../models/recipient.interface';
import { DeviationReportService } from '../services/deviation-report-service';
import { IReportAttachment } from '../models/report-attachment.interface';
import { IDeviationRouteInformation } from '../models/deviation-route-information.interface';

@autoinject()
export class DeviationReportModal {
    @bindable({ defaultBindingMode: bindingMode.twoWay }) totMb = 0;
    routes: IDeviationRoute[];
	deviationReport: IDeviationReport = {} as any;
    loading: boolean;
    
    protected notesInMap: INote[] = [];
    protected typeOfRoutes = ['Krönt vägvals rutt','Önskad rutt','Annat'];
	protected infoRecipient = "Vid fundering om vem som ska vara mottagare av anmälan,\nvälj det VMF dit transporten är destinerad.";
	protected infoDescription = 'Beskriv orsaken till avvikelsen, exempelvis; \n\n - Svängen vid koordinat yyyyyyy, xxxxxx för snäv-kan inte svänga höger med bil o släp. \n - Krönt Vägval ruttar smal väg, vill köra över orten xxxxxxxx pga bredare väg. \n - Krönt Vägval ruttar över bro med begränsat tonnage vid koordinat yyyyyyy, xxxxxx. \n - Krönt Vägval ruttar genom centrum i samhälle xxxxxxxxx'
    protected selectedFiles: File[];
	protected recipientEmails: IRecipient[] = [];
    
	private routesAreValid = true;
    
	formState= {
		avvikelseExpanded: true,
		bifogaExpanded: false,
		myDetailsExpanded: false,
		originalOwnerExpanded: false
	}

    constructor(
        private dialogService: DialogService,
        private controller: DialogController,
        private service: DeviationReportService
    ) {
        this.controller = controller;

        this.notesInMap = this.service.notes;
		this.deviationReport.MyDetails = this.service.lastSenderDetails;
		this.routes = this.service.routes;
        this.recipientEmails = this.service.recipients;

        if (this.hasRoutes) {
			this.routesAreValid = false;
			for (let i = 0; i < this.routes.length; i++) {
				(this.routes[i] as any)._expanded = true;
			}
		}

        this.resetFormState();
    }

    routeTypeChanged(event): void {
		this.validateRoutes();

		if (this.routesAreValid && !this.formState.avvikelseExpanded) {

			this.formState.avvikelseExpanded = true;

			this.routes.forEach(route => {
				(route as any)._expanded = false;
			});

			this.scrollToElement('avvikelseExpanded');
		}
	}

	toggle(key: string) {
		this.formState[key] = !this.formState[key];

		if (!this.formState[key]){
			// Only scroll if expanded
			return;
		}

		this.scrollToElement(key);
	}

	toggleRoute(idx: number) {
		const route = this.routes[idx] as any;
		route._expanded = !route._expanded;
	}

	async sendReport() {
        this.loading = true;
       
        try {
            const mapState = await this.service.saveMapState(this.routes, this.notesInMap);
		
            if (mapState) {
                const href = location.href.split('@');
                this.deviationReport.MapState = href[0]+'@'+mapState;
            }
            
            this.deviationReport.ReportAttachments = [];
            if (this.hasSelectedFiles) {
                for (let index = 0; index < this.selectedFiles.length; index++) {
                    const element = this.selectedFiles[index];
                    const fileToAttach = element;
                    const base64 = await this.service.toBase64(fileToAttach);
                    const attachment: IReportAttachment = {
                        FileName: fileToAttach.name,
                        ContentType: fileToAttach.type,
                        Data: base64
                    };
                    this.deviationReport.ReportAttachments.push(attachment);
                }
    
                this.selectedFiles = [];
            }
    
            this.deviationReport.Routes = [];
            
            if (this.hasRoutes) {
                this.routes.forEach(element => {
                    let vialocationNames = '';
                    if (element.ViaLocations != null) {
                        element.ViaLocations.forEach(location => {
                            vialocationNames = vialocationNames +', '+location.name 
                        });
                    }
                    
                    const routeInformation: IDeviationRouteInformation = {
                        Color: element.Color,
                        Type: element.Type,
                        StartLocationName: element.StartLocation.name,
                        ViaLocationsName: vialocationNames,
                        EndLocationName: element.EndLocation.name
                    }
                    this.deviationReport.Routes.push(routeInformation);
                });
            }
			this.deviationReport.Deviation.Recipient = this.recipientEmails[0].email;
            const res = await this.service.sendReport(this.deviationReport);
            this.loading = false;
            const alertModel: AlertModel = {
                Mode: "success"
            };
    
            if (res === true) {
                alertModel.Mode = "success";
                alertModel.ErrorMessage = "Din anmälan är nu inskickad";
    
                this.resetReport();
            }
            else {
                alertModel.Mode = "error";
                alertModel.ErrorMessage = "Din anmälan kunde inte skickas";
            }
    
            this.dialogService.open({viewModel: Alert, model: alertModel});
        }
        catch (error) {
            this.loading = false;
        }
        finally
        {
            this.controller.ok();
        }
	}

	resetReport() {
		this.deviationReport.Deviation = null;
		this.deviationReport.MapState = null;
		this.deviationReport.MyDetails = null;
		this.deviationReport.OriginalOwner = null;
		this.deviationReport.Routes = null;
        this.controller.cancel();
	}

	getViaLocations(vialoc: IMapRoutePoint[]): string {
		if (!vialoc || vialoc.length == 0) {
			return '';
		}

		return vialoc.map(x=> x.name).join('\r');
	}

	getRouteColor(color: string): string {
		return 'background-color:'+ color + '!important';
	}

	getLength(length: number) {
		const km = length / 1000;
		return km.toFixed(1);
	}

	@computedFrom('routes')
	protected get hasRoutes(): boolean {
		return this.routes && this.routes.length > 0;
	}

	@computedFrom('deviationReport.Deviation.Type', 'deviationReport.Deviation.Recipient', 'deviationReport.Deviation.Description')
	protected get deviationIsValid(): boolean {
		if (!this.deviationReport || !this.deviationReport.Deviation) {
			return false;
		}

		if (this.deviationReport.Deviation.Type && 
			this.deviationReport.Deviation.Description) {
			return true;
		}

		return false;
	}

	@computedFrom('deviationReport.MyDetails.Company', 'deviationReport.MyDetails.Email', 'deviationReport.MyDetails.Phonenumber', 'deviationReport.MyDetails.Name')
	protected get personalInfoIsValid(): boolean {
		if (!this.deviationReport || !this.deviationReport.MyDetails) {
			return false;
		}

		if (this.deviationReport.MyDetails.Company && 
			this.deviationReport.MyDetails.Email && 
			this.deviationReport.MyDetails.Phonenumber && 
			this.deviationReport.MyDetails.Name) {
			return true;	
		}

		return false;
	}
	
	@computedFrom('routesAreValid', 'deviationIsValid', 'personalInfoIsValid', 'fileContentIsValid')
	protected get formIsValid(): boolean {
		if (this.routesAreValid && this.deviationIsValid && this.personalInfoIsValid && this.fileContentIsValid) {
			return true;
		}
		
		return false;
	}

	@computedFrom('totMb')
	private get fileContentIsValid(): boolean {
		return this.totMb < (10*1024*1024);
	}

	@computedFrom('selectedFiles')
	private get hasSelectedFiles(): boolean {
		return this.selectedFiles && this.selectedFiles.length > 0;
	}

	private resetFormState(): void {
		this.formState.avvikelseExpanded = !this.hasRoutes;
		this.formState.bifogaExpanded = false;
		this.formState.myDetailsExpanded = false;
		this.formState.originalOwnerExpanded = false;
	}

	private validateRoutes(): void {
		if (!this.hasRoutes) {
			this.routesAreValid = true;
			return;
		}

		this.routesAreValid = !this.routes.some((x) => !x.Type );
	}

	private async scrollToElement(elementId: string): Promise<void> {
		setTimeout(() => {
			const el = document.getElementById(elementId);
			if (el) {
				el.scrollIntoView({
                    behavior: 'smooth'
                });
			}
		}, 100);
	}

}