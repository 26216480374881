import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { EnvironmentConfiguration } from '../../../../services/configuration/services/configuration';
import { IRouteInformation } from '../../../../services/application-repository/models/route-information.interface';
import { IDeviationReport } from '../models/deviation-report.interface';


@autoinject()
export class ReportService {

    private apiBaseUrl: string;

    constructor(
        private httpClient: HttpClient,
		private config: EnvironmentConfiguration) {
        this.apiBaseUrl = this.config.env.ApiBaseUrl;
    }

    async sendReport(deviationReport: IDeviationReport): Promise<boolean> {
        const url = `${this.apiBaseUrl}/report/sendreport`;
        const config = {
            method: "POST",
            body: JSON.stringify(deviationReport),
            headers : {'Content-Type': 'application/json'}
        };

        return this.httpClient.fetch(url, config)
            .then(x => x.json());
    }
}

export interface INote{
	latlng: L.LatLng;
	point: {x: number; y: number;}; 
	text: string
}

export interface IMapState {
    x?: number;
    y?: number;
    zoom?: number;
    notes?: INote[];
	routesInformation?: IRouteInformation[];
}
