import { transient, autoinject, bindable } from 'aurelia-framework';
import * as L from 'leaflet';
import { Chart, ChartOptions, Point } from 'chart.js';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';


@autoinject()
@transient()
export class RouteChart {
    @bindable route: IRouteInformation;
    @bindable showChart: boolean;
    canvasId: string;

    private canvasContext: CanvasRenderingContext2D;
    private chart: Chart;
    private chartData: Point[]; // TODO verify upgrade.

    private map: L.Map;
    private mapCrs: L.Proj.CRS;
    private marker: L.CircleMarker;
    private minZValue: number;
    private maxZValue: number;

    constructor(private applicationRepo: ApplicationRepository) {
        this.canvasId = new Date().getTime().toString();
    }

    attached(): void {
		this.map = this.applicationRepo.map;
		this.mapCrs = this.applicationRepo.mapCrs;
		
        this.canvasContext = (document.getElementById(this.canvasId) as HTMLCanvasElement).getContext('2d')
        this.chartData = this.getChartData();

        this.renderChart();
    }

    detached(): void {
        if (this.chart) {
            this.chart.destroy();
        }

        this.clearMarker();
    }

    resetGraph(event): void {
        event.stopPropagation();
        if (this.chart) {
            this.renderChart();
        }
    }

    private renderChart(): void {

        if (this.chart) {
            this.chart.destroy();
        }

        const chartOptions: ChartOptions = {
            // onHover: (event: MouseEvent) => {
            //     if (event.type === 'mouseout') {
            //         this.clearMarker();
            //     }
            // },
            tooltips: {
                intersect: false,
                mode: 'nearest',
                callbacks: {
                    title: () => {
                        return ''; // Prevents x-value from being rendered in tooltip
                    },
                    label: (tooltipItem, data) => {
                        this.createMarkerAtIdx(tooltipItem.index);

                        const abbr = data.datasets[tooltipItem.datasetIndex].label || '';
                        return `${tooltipItem.yLabel} ${abbr}`;
                    }
                }
            },
            legend: {
                labels: {
                    filter: (legendItem, chartData) => {
                        return legendItem.datasetIndex === 0;
                    }
                }
            },
            scales: {
                xAxes: [{
                    type: 'linear',
                    stacked: true,
                    position: 'bottom',
                    ticks: {
                        min: 0,
                        max: (this.route.CalculatedRoute as any).CalculatedLength,
                        autoSkip: true,
                        maxRotation: 45,
                        minRotation: 45,
                        callback: (value, index, values) => {
                            return this.formatDistance(value);
                        }
                    }
                }]
            },
            pan: {
                enabled: true,
                mode: 'xy',
                rangeMin: {
                    x: 0,
                    y: this.minZValue
                },
                rangeMax: {
                    x: (this.route.CalculatedRoute as any).CalculatedLength,
                    y: this.maxZValue
                }
            },
            zoom: {
                enabled: true,
                mode: 'xy',
                rangeMin: {
                    x: 0,
                    y: this.minZValue
                },
                rangeMax: {
                    x: (this.route.CalculatedRoute as any).CalculatedLength,
                    y: this.maxZValue
                }
            }
        };

        this.chart = new Chart(this.canvasContext, {
            type: 'line',
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'm.ö.h.',
                        data: this.chartData,
                        pointRadius: 0,
                        backgroundColor: this.hex2rgb(this.route.Color, 0.5),
                        spanGaps: true,
                        cubicInterpolationMode: 'monotone' // 'default', 'monotone'
                    },
                    {
                        label: '',
                        // lineTension: 0,
                        data: this.chartData,
                        pointRadius: 0,
                        backgroundColor: this.hex2rgb(this.route.Color, 0.5)
                    }
                ]
            },
            options: chartOptions
        });
    }

    private formatDistance(source: string): string {
        const intValue = +source;

        if (intValue < 1000) {
			return `${intValue.toFixed(0)} m`;
		}

		const km = intValue / 1000;
		return `${km.toFixed(1)} km`;
    }

    private createMarkerAtIdx(idx: number): void {
        const geom = this.route.CalculatedRoute.Geometry[idx];
        const latlng = this.mapCrs.unproject({ x: geom.X, y: geom.Y } as any);

        if (this.marker) {
            this.marker.setLatLng(latlng);
            return;
        }

        this.marker = L.circleMarker(latlng, {
            color: this.route.Color,
            fillColor: '#fff',
            fillOpacity: 1,
            weight: 2,
            radius: 8
        }).addTo(this.map);
    }

    private clearMarker(): void {
        if (this.marker) {
            this.marker.removeFrom(this.map);
            this.marker = null;
        }
    }

    private getChartData(): Point[] {
        const ret: Point[] = [];
        let maxHeight = NaN;
        let minHeight = NaN;

        const calculatedRoute = this.route.CalculatedRoute;

        const firstPos = calculatedRoute.Geometry[0];

        maxHeight = this.formatHeightValue(firstPos.Z);
        ret.push({x: 0, y: maxHeight });

        let prevPos: { X: number, Y: number, Z: number };
        let currentPos: { X: number, Y: number, Z: number };
        let distance = 0;
        for (let i = 1; i < calculatedRoute.Geometry.length; i++) {
            currentPos = calculatedRoute.Geometry[i];
            prevPos = calculatedRoute.Geometry[i - 1];

            const segLen = this.distance(currentPos, prevPos);
            distance += segLen;

            const height = this.formatHeightValue(currentPos.Z);
            
            if (height > maxHeight) {
                maxHeight = height;
            }

            if (height < minHeight) {
                minHeight = height;
            }

            ret.push({x: distance, y: height });
        }

        this.maxZValue = maxHeight;
        this.minZValue = minHeight;

        return ret;
    }

    private formatHeightValue(source: number): number {
        if (source === -9999900) {
            // Default value when no z data
            return NaN;
        }

        return Math.round(source / 100);
    }

    private distance(geom1, geom2): number {
        // Assuming we are working with meters
        return Math.sqrt(Math.pow(geom1.X - geom2.X, 2) + Math.pow(geom1.Y - geom2.Y, 2));
    }

    private hex2rgb(hex: string, opacity: number): string {
        const trimmed = hex.replace('#', '');
        const regEx = new RegExp(`(.{${trimmed.length/3}})`, 'g');
        const match: any[] =  trimmed.match(regEx);

        for (let i = 0; i < match.length; i++) {
            const value = match[i].length === 1 ? match[i]+match[i] : match[i];
            match[i] = parseInt(value, 16);
        }

        if (typeof opacity !== 'undefined')  {
            match.push(opacity);
        }

        return `rgba(${match.join(',')})`;
    }
}
