import { autoinject, bindable, computedFrom, Disposable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import moment from 'moment';
import * as L from 'leaflet';
import { GeoJsonTypes } from 'geojson';
import { AppAuthService } from 'services/authentication/app-auth-service';
import { AppRole } from 'services/authentication/models/app-roles.enum';
import { StateService } from 'services/state-service';
import { SearchType } from 'services/place-search/models/search-type.enum';
import { EnvironmentConfiguration } from 'services/configuration/services/configuration';
import { IPrincipal } from 'services/authentication/models/principal.interface';
import { PlaceSearchService } from 'services/place-search/place-search-service';
import { IPlaceSearchItem } from 'services/place-search/models/place-search-item.interface';
import { MapEventType } from 'vv-constants/map-event-type.enum';
import { RouteEventItemType } from 'vv-constants/route-event-item-type.enum';
import { IRouteInformation } from 'services/application-repository/models/route-information.interface';
import { IMapRoutePoint } from 'services/application-repository/models/map-route-point.interface';
import { IRouterDefinition } from 'services/application-repository/models/router-definition.interface';
import { IDynamicDataDefinition } from 'services/application-repository/models/dynamic-data-definition.interface';
import { ApplicationRepository } from 'services/application-repository/application-repository';
import { MapConfiguration } from 'services/map-configuration/map-configuration';
import { IResistanceSetting } from 'services/routing/models/resistance-settings.interface';
import { ICoordinateSystem } from 'services/routing/models/coordinate-system.interface';
import { IVehicleDefinition } from 'services/routing/models/vehicle-definition.interface';
import { RoutingService } from 'services/routing/routing-service';
import { IRoutingLocation } from 'services/routing/models/routing-location.interface';
import { ICalculatedRouteRequest } from 'services/routing/models/calculated-route-request.interface';
import { ICalculatedRoute } from 'services/routing/models/calculated-route.interface';
import { IconService } from 'services/assets/services/icon.service';
import { IconType } from 'services/assets/enums/icon-type.enum';

@autoinject()
export class Route {

    @bindable() public route: IRouteInformation;
    @bindable toolPanelExpanded: boolean;

    protected resistanceSettings: IResistanceSetting[];
    protected coordinateSystems: ICoordinateSystem[];
    protected vehicleDefinitions: IVehicleDefinition[];
    protected expanded = true;
    protected minimized = false;
    protected showRoute = true;
    protected showDirection = false;
    protected anyChangesUpdateRoute = false;
    protected calculatingRoute = false;
    private principal: IPrincipal;

    protected chartSettings: {
        showChart: boolean,
        chartEnabled: boolean
    }

    private map: L.Map;
    private mapCrs;

    private startLocation: IMapRoutePoint;
    private endLocation: IMapRoutePoint;
    private viaLocations: IMapRoutePoint[] = [];
    private resistanceSetting: IResistanceSetting;
    private vehicleDefinition: IVehicleDefinition;
    private routers: IRouterDefinition[];
    private router: IRouterDefinition;

    private geojson: any;
    private routeLayerInMap: L.Proj.GeoJSON;
    private routeHaloLayerInMap: L.Proj.GeoJSON;
    private viaMarkers: L.Marker[] = [];
    private startMarker: L.Marker;
    private endMarker: L.Marker;
    private arrowLayer: L.LayerGroup;

    private minimizeRoutesSubscription: Disposable;
    private minimizeRouteSubscription: Disposable;
    private maximizeRoutesSubscription: Disposable;
    private routeSelectedSubscription: Disposable;
    private mapLoadedSubscription: Disposable;
    private mapLocationSelectedSubscription: Disposable;
    private adminRoles = [AppRole.VV_COMMITTER, AppRole.VV_EDITOR, AppRole.VV_ADMIN];

    private isViol3Mode: boolean;
    private dynamicsEnabled: boolean;
    private dynamic: IDynamicDataDefinition;
    private dynamics: IDynamicDataDefinition[];

    @computedFrom('viaLocations.length')
    get canAddViaLocation(): boolean {
        return this.canAddViaPoints();
    }

    /**
     * replaces 'canAddViaPoint' in appRepo with this implementation
     */
    canAddViaPoints = (): boolean => {
        if (!this.viaLocations) {
            return true;
        }

        return this.viaLocations.length < this.applicationRepo.maxNumViaLocations;
    }

    @computedFrom('principal', 'principal.roles')
    get isAdmin(): boolean {
        if (!this.principal) {
            return false;
        }

        return this.principal.someRoles(this.adminRoles);
    }


    constructor(
        private placeSearchService: PlaceSearchService, 
        private routingService: RoutingService, 
        private stateService: StateService, 
        private applicationRepo: ApplicationRepository, 
        private eventAggregator: EventAggregator, 
        private mapConfig: MapConfiguration,
        private appAuthService: AppAuthService,
        private config: EnvironmentConfiguration,
        private iconService: IconService) {
       
        // Toggle Chart
        const toggleConfig = this.config.env.FeatureToggle;
        this.isViol3Mode = toggleConfig.IsViol3;
        this.dynamicsEnabled = toggleConfig.DynamicsAreEnabled;
        this.chartSettings = {
            showChart: false,
            chartEnabled: toggleConfig.RoutingHasZValues
        }
    }

    async bind() {
        this.map = this.applicationRepo.map;
        this.mapCrs = this.applicationRepo.mapCrs;
        this.viaLocations.push(this.getEmptyMapRoutePoint());
        this.isCopy();
        
        const fetchData = async() => {
            const resistanceSetting = await this.routingService.getResistanceSettings();
            const coordinateSystems = await this.routingService.getCoordinateSystems();
            const vehicleDefinitions = await this.routingService.getVehicleDefinitions();

            const routers = await this.stateService.getState('TEST_ROUTE_VVDL').then((data: any) => {
                const ret: IRouterDefinition[] = [{ id: 'PRODUKTION', value: 'Standard', data: 'Standard' }];
                if (data) {
                    const date = moment(data.savedAt).format('YYYYMMDD HH:mm:ss');
                    ret.push({ id: 'TEST', value: 'Provruttning', data: `${data.userName} (${date})` });
                }

                return ret;
            }).catch(error => console.log(error));
            const principal = this.appAuthService.getPrincipal();

            return {
                resistanceSetting: resistanceSetting,
                coordinateSystems: coordinateSystems,
                vehicleDefinitions: vehicleDefinitions,
                routers: principal.someRoles([AppRole.VV_EDITOR, AppRole.VV_COMMITTER]) ? routers: [{ id: 'PRODUKTION', value: 'Standard', data: 'Standard' }]
            }
        }

        // Fetch everything in parallel
        const responses = await fetchData();

        this.resistanceSettings = responses.resistanceSetting;
        this.coordinateSystems = responses.coordinateSystems;
        this.vehicleDefinitions = responses.vehicleDefinitions;
        this.routers = responses.routers || null;
        this.dynamics = [{id:'Nej', value: false},{ id: 'Ja', value: true}];


        this.route.CoordinateSystem = this.coordinateSystems[0];
        if (!this.resistanceSetting || !this.vehicleDefinition || !this.router || !this.dynamic) {
            // First time only
            this.resistanceSetting = this.resistanceSettings[0];
            this.vehicleDefinition = this.vehicleDefinitions[0];
            this.router = this?.routers[0];
            this.dynamic = this.dynamics[0];
        }

        if (!this.toolPanelExpanded && this.route.CalculatedRoute) {
            this.expanded = false;
            this.minimized = true;
            this.route.Selected = false;
        }

        if (this.route && this.route.Selected) {
            this.applicationRepo.canAddViaPoint = this.canAddViaPoints;
        }

        
    }

    attached() {
        this.principal = this.appAuthService.getPrincipal();
        this.routeSelectedSubscription = this.eventAggregator.subscribe(RouteEventItemType.ROUTE_SELECTED, (payload) => {
            if (this.route) {
                if (payload.Id == this.route.Id) {

                    // When this route is selected we have to point the function i repo to this
                    this.applicationRepo.canAddViaPoint = this.canAddViaPoints;

                    if (!payload.IsCopy) {
                        this.minimizedMaximized();
                        if (this.route.Selected == true) {
                            this.route.Selected = false;
                            this.expanded = false;
                        }
                        else {
                            this.route.Selected = true;
                            this.expanded = true;
                        }
                    }

                    if (payload.clearRoute) {
                        this.viaLocations = [];
                        this.viaLocations.push(this.getEmptyMapRoutePoint());
                        this.startLocation = null;
                        this.endLocation = null;
                        this.drawMarkersAndRoutes(false, false);
                        return;
                    }
                }
                else {
                    this.route.Selected = false;
                    this.expanded = false;
                    if (!this.toolPanelExpanded) {
                        this.minimized = true;
                    }
                }

                if (this.route.StartLocation != null && this.showRoute) {
                    this.drawMarkersAndRoutes(true, true)
                }
                else {
                    this.drawMarkersAndRoutes(false, false)
                }

                if(payload.expanded){
                    this.drawMarkersAndRoutes(true,true);
                }
            }
            
        });

        this.minimizeRoutesSubscription = this.eventAggregator.subscribe(RouteEventItemType.MINIMIZE_ROUTES, (payload) => {
            if (this.route && payload == null) {
                this.route.Selected = false;
                this.expanded = false;
                this.minimized = true;
            }
        });

        this.minimizeRouteSubscription = this.eventAggregator.subscribe(RouteEventItemType.MINIMIZE_ROUTE, (payload) => {
            this.route.Selected = false;
            this.expanded = false;
            this.minimized = true;
        });

        this.maximizeRoutesSubscription = this.eventAggregator.subscribe(RouteEventItemType.MAXIMIZE_ROUTES, () => {
            this.minimized = false;
        });

        this.mapLoadedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_LOADED, () => {
            this.map = this.applicationRepo.map;
            this.mapCrs = this.applicationRepo.mapCrs;
        });

        this.mapLocationSelectedSubscription = this.eventAggregator.subscribe(MapEventType.MAP_LOCATION_SELECTED, (locationEvent) => {
            if (this.route.Selected) {
                if (locationEvent.locationType === 'START'){
                    this.startLocationSelected(locationEvent);
                } 
                else if (locationEvent.locationType === 'VIA'){
                    this.viaLocationSelected(locationEvent, null);
                } 
                else if (locationEvent.locationType === 'END') {
                    this.endLocationSelected(locationEvent);
                }
            }
        });
    }

    detached() {
        this.drawMarkersAndRoutes(false, false);
        this.routeSelectedSubscription.dispose();
        this.minimizeRoutesSubscription.dispose();
        this.minimizeRouteSubscription.dispose();
        this.maximizeRoutesSubscription.dispose();
        this.mapLoadedSubscription.dispose();
        this.mapLocationSelectedSubscription.dispose();
    }

    unbind() {
        this.eventAggregator = null;
        this.routeLayerInMap = null;
        this.routeHaloLayerInMap = null;
        this.startMarker = null;
        this.endMarker = null;
        this.viaMarkers = null;
        this.route = null;
    }

    protected minimizedMaximized() {
        if (!this.toolPanelExpanded) {
            if (this.minimized) {
                this.minimized = false;
            }
            else if (!this.minimized) {
                this.minimized = true;
            }

            this.eventAggregator.publish(RouteEventItemType.MINIMIZE_ROUTES, {Id: this.route.Id});
        } 
    }

    protected expand(event): boolean {
        event.stopPropagation()
        this.expanded = this.expanded ? false : true;
        this.eventAggregator.publish(RouteEventItemType.ROUTE_SELECTED, {Id: this.route.Id});
        this.eventAggregator.publish("switcharrow", {expand : false});
        return this.expanded;
    }

    protected toggleChart(event): void {
        event.stopPropagation()
        this.chartSettings.showChart = !this.chartSettings.showChart;
    }

    private isCopy() {
        if (this.route.StartLocation) {
            const jsonStart = JSON.stringify(this.route.StartLocation);
            const jsonEnd = JSON.stringify(this.route.EndLocation);
            const jsonVia = JSON.stringify(this.route.ViaLocations);
            const jsonRes = JSON.stringify(this.route.ResistanceSetting);
            const jsonVeh = JSON.stringify(this.route.VehicleDefinition);
            
            this.startLocation = JSON.parse(jsonStart);
            this.endLocation = JSON.parse(jsonEnd);
            this.viaLocations = JSON.parse(jsonVia);
            this.resistanceSetting = JSON.parse(jsonRes);
            this.vehicleDefinition = JSON.parse(jsonVeh);
            
            if (this.route.Router) {
                const routerJson = JSON.stringify(this.route.Router);
                this.router = JSON.parse(routerJson);
            }
            if(this.route.DynamicData){
                const jsonDyn = JSON.stringify(this.route.DynamicData);
                this.dynamic = JSON.parse(jsonDyn);
            }

            if (this.route.CalculatedRoute) {
                this.expanded = false;
                this.drawMarkersAndRoutes(true, true);
                return;
            }

            this.route.StartLocation = null;
            this.route.EndLocation = null;
            this.route.ViaLocations = [];
            this.route.ViaLocations.push(this.getEmptyMapRoutePoint());
            this.showRoutePointsInMap(this.viaLocations, this.startLocation, this.endLocation);
        }
    }

    //#region calculate route handling
    protected calculateRoute = async (createCopy: boolean) => {
        this.calculatingRoute = true;
        const index = this.viaLocations.findIndex(x => (x == null || x.name == null));
        
        if (index != -1) {
            this.viaLocations.splice(index, 1);
        }

        const viaLocations: IRoutingLocation[] = this.viaLocations.map(x => {
            return {
                Id: x.id,
                Type: x.type,
                X: x.easting,
                Y: x.northing
            };
        });

        const startLocation: IRoutingLocation = {
            Id: this.startLocation.id,
            Type: this.startLocation.type,
            X: this.startLocation.easting,
            Y: this.startLocation.northing
        };

        const endLocation: IRoutingLocation = {
            Id: this.endLocation.id,
            Type: this.endLocation.type,
            X: this.endLocation.easting,
            Y: this.endLocation.northing
        };

        const routeRequest: ICalculatedRouteRequest = {
            From: startLocation,
            To: endLocation,
            Via: viaLocations,
            ResistanceSettingId: this.resistanceSetting.Id,
            EpsgCode: this.route.CoordinateSystem.EPSG,
            VehicleDefinitionId: this.vehicleDefinition.Id,
            UseTrvDynamicData: this.dynamic.value
        };

        if (this.router && this.router.id === 'TEST') {
            routeRequest.UseTestRouter = true;
        }

        try {
            const calculatedRoute: ICalculatedRoute = await this.routingService.calculateRoute(routeRequest);

            if (calculatedRoute.ErrorResponse) {
                this.eventAggregator.publish('alertError', calculatedRoute.ErrorResponse);
                this.calculatingRoute = false;
            }
            else {
                this.setCalculatedRoute(calculatedRoute, createCopy);
            }
            
        }
        catch (error) {
            this.calculatingRoute = false;
        }
        
        
    }
    
    private setCalculatedRoute(calculatedRoute: ICalculatedRoute, createCopy: boolean) {
        const viaLocationsJson = JSON.stringify(this.viaLocations);
        const startLocationsJson = JSON.stringify(this.startLocation);
        const endLocationsJson = JSON.stringify(this.endLocation);
        const vehicleDefJson = JSON.stringify(this.vehicleDefinition);
        const reistanceSettingJson = JSON.stringify(this.resistanceSetting);
        const routerJson = this.router ? JSON.stringify(this.router) : null;
        const dynamicDataJson = JSON.stringify(this.dynamic);

        if (!this.route.Color) {
            this.route.Color = this.applicationRepo.getNextRouteColor();
        }

        const route: IRouteInformation = {
            Id: this.route.Id,
            ViaLocations: JSON.parse(viaLocationsJson),
            StartLocation: JSON.parse(startLocationsJson),
            EndLocation: JSON.parse(endLocationsJson),
            VehicleDefinition: JSON.parse(vehicleDefJson),
            CoordinateSystem: this.route.CoordinateSystem,
            ResistanceSetting: JSON.parse(reistanceSettingJson),
            Router: JSON.parse(routerJson),
            CalculatedRoute: calculatedRoute,
            Active: true,
            Selected: false,
            Color: this.route.Color,
            DynamicData: JSON.parse(dynamicDataJson)
        };

        if (!this.route.CalculatedRoute) {
            this.eventAggregator.publish(RouteEventItemType.ROUTE_CREATED, {
                ViaLocations: JSON.parse(viaLocationsJson),
                StartLocation: JSON.parse(startLocationsJson),
                EndLocation: JSON.parse(endLocationsJson),
                VehicleDefinition: JSON.parse(vehicleDefJson),
                CoordinateSystem: this.route.CoordinateSystem,
                Router: JSON.parse(routerJson),
                ResistanceSetting: JSON.parse(reistanceSettingJson)});
        }

        if (createCopy) {
            route.Color = this.applicationRepo.getNextRouteColor();
            this.eventAggregator.publish(RouteEventItemType.CREATE_ROUTE_COPY, route);
            this.setRouteToOriginalValues();
        } 
        else {
            this.route = route;
            this.applicationRepo.routeInformation = this.route;
            this.expanded = false;
            this.drawMarkersAndRoutes(true, true);
        }
        
        this.calculatingRoute = false;
        
    }

    public createRoute() {
        if (this.route.CalculatedRoute){
            this.calculateRoute(true);
        } 
        else {
            this.calculateRoute(false);
        }
        this.anyChangesUpdateRoute = false;
    }

    public updateRoute() {
        this.calculateRoute(false);
        this.anyChangesUpdateRoute = false;
    }
        
    
    //#endregion

    //#region map handling (routes and markers) 
    private drawMarkersAndRoutes(showPointsInMap: boolean, showRouteInMap: boolean){
        if (this.startMarker) {
            this.startMarker.remove();
            this.startMarker = null;
        }

        if (this.endMarker) {
            this.endMarker.remove();
            this.endMarker = null;
        }

        if (this.viaMarkers) {
            this.viaMarkers.forEach(marker => {
                marker.remove();
            });
            this.viaMarkers = [];
        }
        
        if (this.routeLayerInMap) {
            this.routeLayerInMap.remove();
        }

        if (this.routeHaloLayerInMap) {
            this.routeHaloLayerInMap.remove();
        }

        if (showPointsInMap) {
            this.showRoutePointsInMap(this.viaLocations, this.startLocation, this.endLocation);
        }

        if (showRouteInMap) {
            this.showRouteInMap();
        }
    }

    private showRouteInMap(): void {
        if (!this.route.CalculatedRoute) {
            return;
        }

        this.routeHaloLayerInMap = new L.Proj.GeoJSON;
        this.routeLayerInMap = new L.Proj.GeoJSON;
        this.geojson = {
            type: 'Feature' as GeoJsonTypes,
            geometry: {
                type: 'LineString',
                coordinates: this.route.CalculatedRoute.Geometry.map(
                    x => { return [x.X, x.Y]; })
            },
            crs: {
                type: 'name',
                properties: {
                    name: 'urn:ogc:def:crs:EPSG::3006'
                }
            },
            properties: {
                color: this.route.Color,
                weight: 6
            }
        };

        if (this.route.Selected) {
            this.routeHaloLayerInMap = L.Proj.geoJson(this.geojson, {
                style: function (feature: any) {
                    return {
                        color: '#FFFFFF',
                        opacity: 1,
                        weight: 10,
                        dashArray: "10, 15"
                    };
                }
            });
            this.routeHaloLayerInMap.addTo(this.map);
        }

        this.routeLayerInMap = L.Proj.geoJson(this.geojson, {
            style: function (feature: any) {
                return {
                    color: feature.properties.color,
                    weight: feature.properties.weight,
                    dashArray: "10, 15",
                };
            },
        });
        this.routeLayerInMap.addTo(this.map);

        // Bring selected route to front
        if (this.routeHaloLayerInMap) {
            this.routeHaloLayerInMap.bringToFront();
        }

        if (this.route.Selected) {
            this.routeLayerInMap.bringToFront();
        }
    }

    private showRoutePointsInMap(viaPoints: IPlaceSearchItem[], startPoint: IPlaceSearchItem, endPoint: IPlaceSearchItem): void {
        const draggable = this.route.Selected;
        if (viaPoints != null) {
            const iconViaMap = this.iconService.getIconViaMap();
            for (let i = 0; i < viaPoints.length; i++) {
                if (viaPoints[i] != null && viaPoints[i].name != null) {
                    const latlng = this.mapCrs.unproject({ x: viaPoints[i].easting, y: viaPoints[i].northing });

                    const viaIcon = L.icon({
                        iconUrl: iconViaMap.get(i),
                        iconSize: [26, 38],
                        iconAnchor: [13, 38]
                    });

                    const viaMarker = L.marker(latlng, { icon: viaIcon, draggable: draggable }).addTo(this.map);

                    viaMarker.on('dragend', (e) => {
                        const latlng = new L.LatLng(e.target._latlng.lat, e.target._latlng.lng);
                        viaMarker.setLatLng(latlng);
                        this.setRouteViaLocation(latlng, i);
                    });
                    this.viaMarkers.push(viaMarker);
                }
            }
        }
        
        if (startPoint != null && startPoint.name != null) {
            const startLatlng = this.mapCrs.unproject({ x: startPoint.easting, y: startPoint.northing });
            const startIcon = L.icon({
                iconUrl: this.iconService.getIcon(IconType.IconMapStart),
                iconSize: [26, 38],
                iconAnchor: [13, 38]
            });
            const startMarker = L.marker(startLatlng, { icon: startIcon, draggable: draggable }).addTo(this.map);

            startMarker.on('dragend', (e) => {
                const latlng = new L.LatLng(e.target._latlng.lat, e.target._latlng.lng);
                startMarker.setLatLng(latlng);
                this.setRouteStartLocation(latlng);
            });
            this.startMarker = startMarker;
        }
        
        if (endPoint != null && endPoint.name != null) {
            const endlatlng = this.mapCrs.unproject({ x: endPoint.easting, y: endPoint.northing });
            const stopIcon = L.icon({
                iconUrl: this.iconService.getIcon(IconType.IconMapStopp),
                iconSize: [26, 38],
                iconAnchor: [13, 38]
            });
            const endMarker = L.marker(endlatlng, { icon: stopIcon, draggable: draggable })
                .addTo(this.map);
            this.endMarker = endMarker;

            endMarker.on('dragend', (e) => {
                const latlng = new L.LatLng(e.target._latlng.lat, e.target._latlng.lng);
                endMarker.setLatLng(latlng);
                this.setRouteEndLocation(latlng);
            });
            this.endMarker = endMarker;
        }
    }

    //#region marker dragging event handling
    private setRouteViaLocation(latlng: L.LatLng, index: number) {
        const location = this.createNewLocation(latlng);
        const viaLocation = {
            id: "",
            name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
            type: SearchType.COORDINATE,
            latlng: location.latlng,
            projected: location.projected,
            easting: location.projected.x,
            northing: location.projected.y
        };
        this.viaLocations.splice(index, 1, viaLocation);

        this.anyChanges();
    }

    private setRouteEndLocation(latlng: L.LatLng) {
        const location = this.createNewLocation(latlng);
        this.endLocation = {
            id: "",
            name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
            type: SearchType.COORDINATE,
            latlng: location.latlng,
            projected: location.projected,
            easting: location.projected.x,
            northing: location.projected.y
        };

        this.anyChanges();
    }

    private setRouteStartLocation(latlng: L.LatLng) {
        const location = this.createNewLocation(latlng);
        this.startLocation = {
            id: "",
            name: Math.round(location.projected.x) + ', ' + Math.round(location.projected.y),
            type: SearchType.COORDINATE,
            latlng: location.latlng,
            projected: location.projected,
            easting: location.projected.x,
            northing: location.projected.y
        };

        this.anyChanges();
    }

    private createNewLocation(latlng: L.LatLng): IMapRoutePoint {
        const newPoint = this.mapConfig.getMapCrs().crs.project(latlng);
        const mapMarker = {
            id: "",
            name: Math.round(newPoint.x) + ', ' + Math.round(newPoint.y),
            type: SearchType.COORDINATE,
            latlng: latlng,
            projected: newPoint,
            easting: newPoint.x,
            northing: newPoint.y
        };

        return mapMarker;
    }
    //#endregion

    //#endregion

    //#region button and formating (gui) handling
    public removeRoute(event){
        event.stopPropagation();
        this.applicationRepo.removeRouteInformation(this.route);
    }

    public changeRouteDirection() {
        let tempStart: IMapRoutePoint;
        let tempEnd: IMapRoutePoint;

        if (this.startLocation.name) {
            tempEnd = this.startLocation;
        } 
        else {
            tempEnd = null;
        }

        if (this.endLocation.name) {
            tempStart = this.endLocation;
        } 
        else {
            tempStart = null;
        }

        this.startLocation = tempStart;
        this.endLocation = tempEnd;
        this.viaLocations.reverse();
        this.drawMarkersAndRoutes(true, true);
        this.anyChanges();
    }

    public toggleShowRoute(event){
        event.stopPropagation();
        this.showRoute = this.showRoute ? false : true;
        
        if (this.showRoute) {
            this.drawMarkersAndRoutes(true, true);
            this.showDirection = false;
        } 
        else {
            this.drawMarkersAndRoutes(false, false);
            this.routeLayerInMap.removeLayer(this.arrowLayer);
            this.showDirection = false;
        }
        
    }

    public toggleShowDirection(event){
        event.stopPropagation();
        this.showDirection = this.showDirection ? false : true;

        if(!this.arrowLayer){
            this.arrowLayer = new L.LayerGroup;
        }

        if (this.showDirection) {
            const t: L.LatLng[] = [];
            this.geojson.geometry.coordinates.forEach(element => {
                const startLatlng = (this.applicationRepo.mapCrs as any).unproject({ x: element[0], y: element[1] });
                t.push(startLatlng);
            });

            const polyline = L.polyline(t, {
                color: this.geojson.properties.color,
                weight: 6
            }).addTo(this.arrowLayer);

            L.polylineDecorator(polyline, {
                patterns: [{
                    offset: 10,
                    repeat: 60,
                    symbol: L.Symbol.arrowHead({
                        pixelSize: 10,
                        pathOptions: { fillOpacity: 1, weight: 5, color: this.geojson.properties.color }
                    })
                }]
            }).addTo(this.arrowLayer);
            this.arrowLayer.addTo(this.routeLayerInMap);
        }

        else {
            this.routeLayerInMap.removeLayer(this.arrowLayer);
        }
    }

    public zoomTo(event) {
        event.stopPropagation();
        const geometry = [];
        this.route.CalculatedRoute.Geometry.forEach(point => {
            const geom = {y: point.Y, x: point.X};
            geometry.push(this.applicationRepo.mapCrs.unproject(geom as any));
        });

        const bounds = new L.LatLngBounds(geometry);
        this.map.fitBounds(bounds);
    }

    private setRouteToOriginalValues() {
        const routeStartLocationJson = JSON.stringify(this.route.StartLocation);
        const routeEndLocationJson = JSON.stringify(this.route.EndLocation);
        const routeViaLocationsJson = JSON.stringify(this.route.ViaLocations);

        if (this.route.StartLocation && this.route.StartLocation.name != this.startLocation.name) {
            this.startLocation = JSON.parse(routeStartLocationJson);
        }
        else if (!this.route.CalculatedRoute) {
            this.startLocation = null;
            this.startLocation = this.getEmptyMapRoutePoint();
        }

        if (this.route.EndLocation && this.route.EndLocation.name != this.endLocation.name) {
            this.endLocation = JSON.parse(routeEndLocationJson);
        }
        else if (!this.route.CalculatedRoute) {
            this.endLocation = null;
            this.endLocation = this.getEmptyMapRoutePoint();
        }

        if (!this.route.ViaLocations || this.route.ViaLocations == null) {
            this.viaLocations = this.route.ViaLocations;
        }
        else if (this.route.ViaLocations.length != this.viaLocations.length) {
            this.viaLocations = this.route.ViaLocations;
        } 
        else if (this.route.ViaLocations.length == this.viaLocations.length) {
            for (let i = 0; i < this.viaLocations.length; i++) {
                if (this.viaLocations[i].name != this.route.ViaLocations[i].name) {
                    this.viaLocations = JSON.parse(routeViaLocationsJson);
                    break;
                }
            }
        }

        if (this.route.VehicleDefinition) {
            if (!this.route.VehicleDefinition || this.route.VehicleDefinition.Id != this.vehicleDefinition.Id) {
                const routeVehicleDefinitionJson = JSON.stringify(this.route.VehicleDefinition);
                this.vehicleDefinition = JSON.parse(routeVehicleDefinitionJson);
            }
        }
        else {
            const vehicleDefinitionJson = JSON.stringify(this.vehicleDefinitions[0]);
            this.vehicleDefinition = JSON.parse(vehicleDefinitionJson);
        }

        if (this.route.ResistanceSetting) {
            if (!this.route.ResistanceSetting || this.route.ResistanceSetting.Name != this.resistanceSetting.Name) {
                const routeResistanceSettingJson = JSON.stringify(this.route.ResistanceSetting);
                this.resistanceSetting = JSON.parse(routeResistanceSettingJson);
            }
        }
        else {
            const resistanceSettingsJson = JSON.stringify(this.resistanceSettings[0]);
            this.resistanceSetting = JSON.parse(resistanceSettingsJson);
        }

        if (this.route.Router) {
            const routerJson = JSON.stringify(this.route.Router);
            this.router = JSON.parse(routerJson);
        }
        else {
            const routerJson = JSON.stringify(this.routers[0]);
            this.router = JSON.parse(routerJson);
        }
        if (this.route.DynamicData) {
            const dynJson = JSON.stringify(this.route.DynamicData);
            this.dynamic = JSON.parse(dynJson);
        }
        else {
            const dynJson = JSON.stringify(this.dynamics[0]);
            this.dynamic = JSON.parse(dynJson);
        }
        
        this.drawMarkersAndRoutes(true, true);
        this.anyChangesUpdateRoute = false;
    }

    private anyChanges() {
        if (!this.route.CalculatedRoute) {
            this.anyChangesUpdateRoute = false;
            return;
        }

        if (!this.startLocation || !this.endLocation) {
            this.anyChangesUpdateRoute = false;
            return;
        } 
        else if (this.startLocation.name != this.route.StartLocation.name) {
            this.anyChangesUpdateRoute = true;
            return;
        } 
        else if (this.endLocation.name != this.route.EndLocation.name) {
            this.anyChangesUpdateRoute = true;
            return;
        } 
        else if (this.viaLocations.length != this.route.ViaLocations.length) {
            this.anyChangesUpdateRoute = true;
            return;
        } 
        else if (this.resistanceSetting.Id != this.route.ResistanceSetting.Id) {
            this.anyChangesUpdateRoute = true;
            return;
        } 
        else if (this.vehicleDefinition.Id != this.route.VehicleDefinition.Id){
            this.anyChangesUpdateRoute = true;
            return;
        } 
        else if (this.router && this.router.id != this.route.Router.id){
            this.anyChangesUpdateRoute = true;
            return;
        }
        else if(this.dynamic && this.dynamic.id != this.route.DynamicData.id){
            this.anyChangesUpdateRoute = true;
            return;
        } 

        for (let i = 0; i < this.route.ViaLocations.length; i++) {
            if (this.viaLocations[i].name != this.route.ViaLocations[i].name) {
                this.anyChangesUpdateRoute = true;
                return;
            }
        }

        this.anyChangesUpdateRoute = false;
    }

    protected getColor(){
        return 'background-color: ' + this.route.Color + '!important';
    }

    protected getLength(length: number, asMeters=false) {
        if (length < 1000 || asMeters) {
            return length + ' m';
        }

        const km = length / 1000;
        return km.toFixed(1) + ' km';
    }

    protected addViaLocation() {
        if (!this.viaLocations) {
            this.viaLocations = [];
        }

        if (this.viaLocations.length > 0 && 
            this.viaLocations[this.viaLocations.length-1] == null) {
            // Last of via locations is null so not adding any more vias
            return;
        }

        if (this.viaLocations[this.viaLocations.length-1] &&
            !this.viaLocations[this.viaLocations.length-1].name) {
            // Last of via locations is "incomplete" so not adding any more vias
            return;
        }

        this.viaLocations.push(this.getEmptyMapRoutePoint());
        this.anyChanges();
    }

    protected removeVia(index) {
        this.viaLocations.splice(index, 1);
        this.drawMarkersAndRoutes(true, true);
        this.anyChanges();
    }

    protected removeStart() {
        this.startLocation = null;
        this.startLocation = this.getEmptyMapRoutePoint();
        this.drawMarkersAndRoutes(true, true);
        this.anyChanges();
    }

    protected removeEnd() {
        this.endLocation = null;
        this.endLocation = this.getEmptyMapRoutePoint();
        this.drawMarkersAndRoutes(true, true);
        this.anyChanges();
    }
    //#endregion

    //#region autocomplete
    protected search =  (s: string) => {
        return this.placeSearchService.getSuggestions(s, [SearchType.SITE, SearchType.COORDINATE]);
    }

    protected filterLastSearches = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {
        const filtered = items.filter( x => x.type === SearchType.SITE || x.type === SearchType.COORDINATE);
        return filtered;
    }

    protected filterFavorites = (items: IPlaceSearchItem[]): IPlaceSearchItem[] => {
        const filtered = items.filter( x => x.type === SearchType.SITE || x.type === SearchType.COORDINATE);
        return filtered;
    }

    protected formatItem = (searchItem: IPlaceSearchItem) => {
        return this.placeSearchService.formatItem(searchItem);
    }

    /**
     * Listens to when any location has been "unselected" from autocomplete inputs
     * @param event 
     */
    protected locationUnselected(event: any) {
        let shouldRedraw = !this.startLocation || !this.endLocation;

        if (!shouldRedraw) {
            for (let i = 0; i < this.viaLocations.length; i++) {
                if(this.viaLocations[i]) {
                    continue;
                }

                shouldRedraw = true;
                break;
            }
        }

        if (shouldRedraw) {
            this.drawMarkersAndRoutes(true, true);
            this.anyChanges();
        }
    }

    protected viaLocationSelected(event: any, index: number) {
        let searchItem;
        if (event.detail) {
            searchItem = event.detail as IMapRoutePoint;
        } 
        else {
            searchItem = event as IMapRoutePoint;
        }
        
        if (searchItem.name != null) {
            const lastViaLocationIsEmpty = () => {
                if (!this.viaLocations || this.viaLocations.length === 0) {
                    return false;
                }

                if (!this.viaLocations[this.viaLocations.length-1] ||
                    !this.viaLocations[this.viaLocations.length-1].name) {
                    return true;
                }

                return false;
            };

            // if (lastViaLocationIsEmpty()) {
            // 	this.viaLocations.splice(this.viaLocations.length-1, 1, searchItem);
            // } 
            if (index == null) {
                if (lastViaLocationIsEmpty()) {
                    this.viaLocations.splice(this.viaLocations.length-1, 1, searchItem);
                } 
                else {
                    this.viaLocations.push(searchItem);
                }
                
            } 
            else {
                this.viaLocations.splice(index, 1, searchItem);
            }
            this.drawMarkersAndRoutes(true, true);	
        }
        this.anyChanges();
    }

    protected startLocationSelected(event: any) {
        let searchItem;
        if (event.detail) {
            searchItem = event.detail as IMapRoutePoint;
        } 
        else {
            searchItem = event as IMapRoutePoint;
        }
        
        if (searchItem.name != null) {
            this.startLocation = searchItem;
            this.drawMarkersAndRoutes(true, true);	
        }
        this.anyChanges();
    }

    protected endLocationSelected(event: any) {
        let searchItem;
        if (event.detail) {
            searchItem = event.detail as IMapRoutePoint;
        } 
        else {
            searchItem = event as IMapRoutePoint;
        }

        if (searchItem.name != null) {
            this.endLocation = searchItem;
            this.drawMarkersAndRoutes(true, true);	
        }
        this.anyChanges();
    }
    //#endregion

    private getEmptyMapRoutePoint(): IMapRoutePoint {
        return {
            id: null,
            type: null,
            name: null,
            latlng: null,
            projected: null
        };
    }
}
