// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.route-chart-container {
    background-color: #fff;
    margin-top: 5px;
    margin-bottom: 5px;
}

.route-chart-container .command-container {
    text-align: right;
    padding-bottom: 5px;
}

.route-chart-container .command-container span {
    cursor: pointer;
    color: #777777;
    margin-right: 10px;
}

.route-canvas-container {
    width: 100%; 
    height: 200px;
}`, "",{"version":3,"sources":["webpack://./src/components/map/routing/route/route-chart/route-chart.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB","sourcesContent":[".route-chart-container {\r\n    background-color: #fff;\r\n    margin-top: 5px;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.route-chart-container .command-container {\r\n    text-align: right;\r\n    padding-bottom: 5px;\r\n}\r\n\r\n.route-chart-container .command-container span {\r\n    cursor: pointer;\r\n    color: #777777;\r\n    margin-right: 10px;\r\n}\r\n\r\n.route-canvas-container {\r\n    width: 100%; \r\n    height: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
